.page_apri_tavolo {
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 400px;
  height: auto;
  align-items: center;
  justify-content: center;
  margin: auto;
}
