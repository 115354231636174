.container-home {
  
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  height: auto;
  min-height: 90vh;
  margin: auto;
  width: 70vw;
}

.info_box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  text-align: justify;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.info {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.come_fare {
  display: flex;
  flex-direction: column;
  width: 49%;
  height: auto;
  align-items: center;
  justify-content: center;
}
.come_fare p {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 1.6rem;
}
.qr_code_container {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
}
.qr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  box-sizing: border-box;
  width: 100%;
}
.testo-uso {
  width: 100%;
}
.testo_qr {
  display: flex;
  flex-direction: row;
  width: 80%;
  height: auto;
  align-items: center;
  justify-content: center;
  font-variant: small-caps;
  padding: 5px auto;
  text-align: center;
}
.raccolta {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: 20px 0;
}
.mail {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 20px 0 10px 0;
}
.raccolta .spazio_input {
  width: 100%;
  margin-bottom: 10px;
  justify-content: space-between;
}
.raccolta .spazio_input span {
  width: auto;
  margin: auto 0;
}
.raccolta .spazio_input .btn {
  align-self: flex-end;
  margin: 0;
}
.check_privacy {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  align-items: center;
  justify-content: flex-start;
}
.check_box {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  margin: auto 5px;
}

@media all and (max-width: 600px) {
  .container-home {
    width: 95%;
    flex-direction: column;
    padding: 40px 0;
  }
  .info {
    flex-direction: column;
    width: 100%;
  }
  .come_fare {
    width: 100%;
  }
  .come_fare p {
    width: 90%;
  }
  .qr_code_container {
    width: 100%;
    margin: 20px 0;
  }
  .qr {
    width: 100%;
    height: auto;
    margin: 0;
  }
}
