.btn {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  padding: 10px 55px;
  border-radius: 15px;
  margin: 0 auto;
  border: 1px solid #928c8c;
  font-variant: small-caps;
  color: #282828;
  transition: all ease-in-out 0.4s;
  align-items: center;
  justify-content: center;
}
.btn:hover {
  background-color: #184491;
  color: #ffffff;
}
.button:active {
  background-color: #282828;
  color: #ffffff;
}
.circle {
  width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 50%;
}
.send_order {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  padding: 10px 35px;
  border-radius: 0;
  margin: 0;
  border: none;
  font-variant: small-caps;
  color: #282828;
  transition: all ease-in-out 0.4s;
  align-items: center;
  justify-content: center;
}
.nota_btn {
  width: auto;
  padding: 8px 12px;
}
.full-width {
  width: 100%;
}
.inc {
  display: flex;
  flex-direction: row;
  width: auto;
  min-width: 100px;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
}
.dec {
  display: flex;
  flex-direction: row;
  width: auto;
  min-width: 100px;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
}
.card .inc a, .card .dec a {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.note_btn{
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 0;
  padding: 6px 12px;
  border-radius: 15px;
  font-variant: small-caps;
  margin: 0 auto;
  border: 1px solid #928c8c;
  font-variant: small-caps;
  color: #282828;
  transition: all ease-in-out 0.4s;
  align-items: center;
  justify-content: center;
}
.note_btn:hover {
  background-color: #184491;
  color: #ffffff;
}
.note_btn:active {
  background-color: #282828;
  color: #ffffff;
}